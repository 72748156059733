<template>
  <div class="add_product">
    <van-nav-bar
        title="编辑产品"
        left-text="返回"
        left-arrow
        fixed
        @click-left="onClickLeft"
    />
    <scroll class="add_product_scroll" :onLoadMore="onLoadMore">
      <van-form @submit="onSubmit" class="add_product_from">
        <van-field
            v-model="productCode"
            label="产品编号"
            name="productCode"
            left-icon=" icon icon-bitian"
            placeholder="请输入产品编号"
        />
        <van-field
            v-model="productName"
            label="产品名称"
            name="productName"
            left-icon=" icon icon-bitian"
            placeholder="请输入产品名称"
        />
        <van-field
            v-model="productUnit"
            readonly
            clickable
            label="单位"
            name="productUnit"
            left-icon=" icon icon-bitian"
            placeholder="点击选择单位"
            @click="show = true"
        />
        <van-field
            v-model="productRank"
            label="产品规格"
            name="productRank"
            left-icon=" icon icon-bitian"
            placeholder="请输入产品规格"
        />
        <customise ref="customise" :defType="defType"></customise>
        <van-field
            v-model="remake"
            label="备注"
            name="remake"
            placeholder="请输入备注"
        />
        <div style="margin: 16px" class="submit">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
      <van-popup v-model="show" round position="bottom">
        <van-picker
            show-toolbar
            :columns="unitNameList"
            @confirm="onConfirm"
            @cancel="show = false"
        />
      </van-popup>
    </scroll>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import customise from "@/components/customise/index";
import {ChangePageTitle} from "../../common/js/upTitle";
import {mapGetters} from "vuex";
import {toUpdate, getProduct, addProduct, getEwoUnitList} from "@/api/product.js";

export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      defType: 1,
      productId: "", //产品id
      productCode: "", //产品编号
      productName: "", //产品名称
      productRank: "", //产品规格
      remake: "", //备注
      definitionProperties: "", //定义字段，下面列表循环
      unitNameList: [], //所有计量单位
      productUnit: "", //计量单位
      bomItem: [],
      moderText: [], //definitionType == 1
      moderNumber: [], //definitionType == 2
      show: false,
      dataShow: false,
      dataId: 0, //选择日期id
      colorListShow: false, //颜色选择
      moderTime: [], //definitionType == 3
      moderImg: [], //definitionType == 4
      moderRa: [], //definitionType == 5
      moderCh: [], //definitionType == 6
    };
  },
  created() {
    ChangePageTitle("编辑产品");
    if (window.localStorage.getItem('prodcutId')) {
      this.productId = window.localStorage.getItem('prodcutId');
      this.getProduct(this.productId);
    } else {
      this.addProduct(this["departmentCode"]);
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push("/selectProduct");
      window.localStorage.removeItem('prodcutId')
    },
    //添加
    async addProduct(departmentCode) {
      const res = await getEwoUnitList(departmentCode);
      res.forEach((v) => {
        this.unitNameList.push(v.unitName);
      });
    },
    onConfirm(value) {
      this.productUnit = value;
      this.show = !this.show;
    },
    //编辑修改提交
    async onSubmit() {
      if (
          this.productCode == "" ||
          this.productName == "" ||
          this.productRank == "" ||
          this.productUnit == "" ||
          this.bomItem == []
      ) {
        return;
      } else {
        var list = {
          remake: this.remake,
          productCode: this.productCode,
          productName: this.productName,
          productRank: this.productRank,
          productUnit: this.productUnit,
          bomItem: this.bomItem
        };
      }
      const res = await this.$refs.customise.onModulSubmit();
      //判断id是否为空
      if (this.productId == "") {
        this.$toast("添加成功");
        this.addProductData(list, res);
      } else {
        this.$toast("更新成功");
        this.toUpdate(list, res);
      }
    },
    //修改提交
    async toUpdate(list, jsonData) {
      try {
        list.definitionProperties = jsonData;
        list.id = this.productId;
        list.departmentCode = this["departmentCode"];
        const res = await toUpdate(list);
        this.$router.push("selectProduct");
      } catch (err) {
        console.log(err);
      }
    },
    //添加
    async addProductData(list, jsonData) {
      try {
        list.definitionProperties = jsonData;
        list.departmentCode = this["departmentCode"];
        await addProduct(list);
        this.$toast.success("添加成功");
        this.$router.push("selectProduct");
      } catch (err) {
        console.log(err);
      }
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    //获取产品详情
    async getProduct(id) {
      const res = await getProduct(id);
      //定义查询模板需要的数据
      var params = {
        jsonData: "",
        departmentCode: "",
        type: "1", //产品类型1
      };
      this.productCode = res.productCode;
      this.productName = res.productName;
      this.productRank = res.productRank;
      this.remake = res.remake;
      this.productUnit = res.productUnit;
      this.bomItem = res.bomItem
      if (res.definitionProperties != null) {
        //将定义字段解析成为数组
        params.jsonData = res.definitionProperties;
      }
      params.departmentCode = this["departmentCode"];
      //查询计量单位
      this.getEwoUnitList();
    },
    //根据所属公司code查询计量单位
    async getEwoUnitList() {
      const departmentCode = this["departmentCode"];
      const res = await getEwoUnitList(departmentCode);
      res.forEach((v) => {
        this.unitNameList.push(v.unitName);
      });
    },
  },
  components: {
    scroll,
    customise,
  },
};
</script>
<style lang="less" scoped>
.add_product {
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  position: relative;

  .add_product_scroll {
    padding-top: 80px;

    /deep/ .icon-bitian {
      color: red;
    }
  }

  .add_product_from {
    margin-top: 20px;
  }

  .van-cell {
    width: 95%;
    margin: auto;
    border-radius: 10px;
    margin-top: 30px !important;
  }

  .color_check {
    height: 50vh;
  }

  .submit {
    margin-top: 40px !important;
  }

  .model_type1 {
    border-left: 5px solid #1989fa;
  }

  .model_type2 {
    border-left: 5px solid #07c160;
  }

  .model_type3 {
    border-left: 5px solid rgb(114, 50, 221);
  }

  .model_type4 {
    align-items: center;
    border-left: 5px solid red;

    .up_img {
      width: 20vw;
      height: 20vw;
      position: relative;

      img {
        width: 100%;
      }

      .icon {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
        color: red;
      }
    }
  }

  .model_type5 {
    border-left: 5px solid #312626;
    align-items: center;

    .van-radio--horizontal {
      margin-top: 5px;
    }
  }

  .model_type6 {
    border-left: 5px solid #4e89b3;
    align-items: center;

    .van-checkbox {
      margin-top: 5px;
    }
  }

  .color_content {
    margin-top: 40px;
    padding: 10px;

    .van-checkbox {
      margin: 10px 0;
    }
  }
}
</style>
